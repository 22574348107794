import { Asset } from "contentful";

import {
  ContentfulCustomNode,
  CustomContentModelType,
} from "#components/contentful/contentful.custom.types";

export type ResponsiveImageFields = {
  desktopImage: Asset;
  mobileImage?: Asset;
  specialOverride?: string;
};

export type ResponsiveImageSection = ContentfulCustomNode<
  ResponsiveImageFields,
  CustomContentModelType.ResponsiveImage
>;
export enum ContentfulFit {
  /** Resize the image to the specified dimensions, padding the image if needed. */
  pad = "pad",
  /** Resize the image to the specified dimensions, cropping the image if needed. */
  fill = "fill",
  /** Resize the image to the specified dimensions, changing the original aspect ratio if needed. */
  scale = "scale",
  /** Crop a part of the original image to fit into the specified dimensions. */
  crop = "crop",
  /** Create a thumbnail from the image. */
  thumb = "thumb",
}
export enum ContentfulFocus {
  center = "center",
  top = "top",
  right = "right",
  left = "left",
  bottom = "bottom",
  topRight = "top_right",
  topLeft = "top_left",
  bottomRight = "bottom_right",
  bottomLeft = "bottom_left",
  /** largest face detected */
  face = "face",
  /** all faces detected */
  faces = "faces",
}
export enum ImageFormat {
  jpg = "jpg",
  png = "png",
  webp = "webp",
  gif = "gif",
  avif = "avif",
}
export enum ImageOpti {
  /** only for jpgs */
  progressive = "progressive",
  /** only for pngs */
  png8 = "png8",
}
export type ImgParams = {
  /** width */
  w?: number;
  /** height */
  h?: number;
  fit?: ContentfulFit;
  /** focus - for resizing when using fit type pad, fill, crop or thumb. */
  f?: ContentfulFocus;
  /** format */
  fm?: ImageFormat;
  /** formal optimization */
  fl?: ImageOpti;
};
export type ResponsiveImageProps = {
  imgParams?: ImgParams;
};

export enum SpecialOverride {
  HOMEPAGE = "homepage",
}
