/* eslint-disable @typescript-eslint/restrict-template-expressions */
import dynamic from "next/dynamic";
import { cloneElement } from "react";
import { v4 as uuidv4, v5 as uuidv5 } from "uuid";

import { CustomContentModelType } from "#components/contentful/contentful.custom.types";
import {
  ContentfulComponentProps,
  ContentfulCustomNodeImpl,
} from "#components/contentful/contentful.types";
import AddonsAndBenefits from "#components/contentful/contentful-v2/AddonsAndBenefits/AddonsAndBenefits";
import EmailSubscriptionBanner from "#components/contentful/EmailSubscriptionBanner/EmailSubscriptionBanner";
import { ContactUs } from "#components/contentful/ReadyToApplyCard/ReadyToApplyCard";
import ABTest from "#v2-components/organisms/ABTest";
import ErrorBoundary from "#v2-components/organisms/ErrorBoundary";

const PageContent = dynamic(
  () => import("#components/contentful/v5/PageContent"),
);
const SplitLinkedCard = dynamic(
  () =>
    import(
      "#components/contentful/contentful-v2/SplitLinkedCard/SplitLinkedCard"
    ),
);
const Commitment = dynamic(
  () => import("#components/contentful/Commitment/Commitment"),
);
const DescriptionPanel = dynamic(
  () => import("#components/contentful/DescriptionPanel/DescriptionPanel"),
);
const Footer = dynamic(() => import("#components/contentful/Footer/Footer"));
const NewsSection = dynamic(
  () => import("#components/contentful/InTheNews/InTheNews"),
);
const StackableStepsSection = dynamic(
  () => import("#components/contentful/StackableSteps/StackableSteps"),
);
const ResponsiveImage = dynamic(
  () => import("#components/contentful/ResponsiveImage/ResponsiveImage"),
);
const CustomerTestimonials = dynamic(
  () =>
    import("#components/contentful/CustomerTestimonials/CustomerTestimonials"),
);
const FAQSection = dynamic(
  () => import("#components/contentful/FAQSection/FAQSection"),
);
const LargeImage = dynamic(
  () => import("#components/contentful/LargeImage/LargeImage"),
);
const Blender = dynamic(() => import("#components/contentful/Blender/Blender"));
const CommonQuestions = dynamic(
  () => import("#components/contentful/CommonQuestions/CommonQuestions"),
);
const BlogCardSection = dynamic(
  () => import("#components/contentful/BlogCardSection/BlogCardSection"),
);
const BlogSection = dynamic(
  () => import("#components/contentful/BlogSection/BlogSection"),
);
const GetInTouchCareersCard = dynamic(
  () =>
    import(
      "#components/contentful/GetInTouchCareersCard/GetInTouchCareersCard"
    ),
);
const TeamCarousel = dynamic(
  () => import("#components/contentful/TeamCarousel/TeamCarousel"),
);

const BannerSection = dynamic(
  () => import("#components/contentful/Banner/Banner"),
);
const CarFeature = dynamic(
  () => import("#components/contentful/CarFeature/CarFeature"),
);
const DetailsSection = dynamic(
  () => import("#components/contentful/Details/Details"),
);
const FeatureComparison = dynamic(
  () => import("#components/contentful/FeatureComparison/FeatureComparison"),
);
const JobOpenings = dynamic(
  () => import("#components/contentful/JobOpenings/JobOpenings"),
);
const PartnerHero = dynamic(
  () => import("#components/contentful/PartnerHero/PartnerHero"),
);
const RoleDescription = dynamic(
  () => import("#components/contentful/RoleDescription/RoleDescription"),
);
const StackablesSection = dynamic(
  () => import("#components/contentful/StackablePanes/StackablePanes"),
);
const Testimonials = dynamic(
  () => import("#components/contentful/Testimonials/Testimonials"),
);
const TitleSection = dynamic(
  () => import("#components/contentful/TitleSection/TitleSection"),
);

const Header = dynamic(() => import("#components/contentful/Header/Header"));
const TeslaHero = dynamic(
  () => import("#components/contentful/TeslaHero/TeslaHero"),
);
const IconBanner = dynamic(
  () => import("#components/contentful/IconBanner/IconBanner"),
);

const HomeHero = dynamic(
  () => import("#components/contentful/contentful-v2/HomeHero/HomeHero"),
);
const DividerSection = dynamic(
  () => import("#components/contentful/DividerSection/DividerSection"),
);

const LinkedTileCard = dynamic(
  () => import("#components/contentful/LinkedTileCard/LinkedTileCard"),
);

const HowItWorks = dynamic(
  () => import("#components/contentful/contentful-v2/HowItWorks/HowItWorks"),
);

const Navbar = dynamic(
  () => import("#components/contentful/contentful-v2/Navbar/Navbar"),
);

const AddOnPage = dynamic(
  () => import("#components/contentful/AddOnPage/AddOnPage"),
);

const MakePage = dynamic(
  () => import("#components/contentful/MakePage/MakePage"),
);
const RichTextComponent = dynamic(
  () => import("#components/contentful/RichTextComponent/RichTextComponent"),
);
const ValuePropSection = dynamic(
  () =>
    import(
      "#components/contentful/contentful-v2/ValuePropSection/ValuePropSection"
    ),
);
const AccordionSection = dynamic(
  () =>
    import(
      "#components/contentful/contentful-v2/AccordionSection/AccordionSection"
    ),
);
const HeroWithIcon = dynamic(
  () =>
    import("#components/contentful/contentful-v2/HeroWithIcon/HeroWithIcon"),
);
const SmallSection = dynamic(
  () =>
    import("#components/contentful/contentful-v2/SmallSection/SmallSection"),
);
const LargeSection = dynamic(
  () =>
    import("#components/contentful/contentful-v2/LargeSection/LargeSection"),
);
const Metadata = dynamic(() => import("#components/contentful/Metadata"));

const UUIDNamespace = uuidv4();

/**
 * Unfortunately type narrowing doesn't play nicely with generics or
 * nested attributes, so here we coerce the type and bring the narrowing
 * attribute (contentType ID) to the top level
 * */
export const coerceContentfulType = <T extends ContentfulCustomNodeImpl>(
  content: T,
): T => {
  if (content.contentTypeID != null) {
    // this content is a mock and does not need coercion
    return content;
  }
  if (content?.sys?.contentType?.sys?.id == null) {
    return {
      ...content,
      contentTypeID: CustomContentModelType.Error,
    } as T;
  }
  return {
    ...content,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    contentTypeID: content.sys.contentType.sys.id,
  };
};

export const renderContentModelWithoutBounds = (
  c: ContentfulCustomNodeImpl,
  componentProps?: ContentfulComponentProps,
  options?: {
    onHeroVisibilityDetected?: () => void;
    belowFold?: boolean;
  },
) => {
  const content = coerceContentfulType(c);
  const contentSection = (() => {
    switch (content.contentTypeID) {
      case CustomContentModelType.Error:
        const contentId = content.sys.id;
        const contentLink = `https://app.contentful.com/spaces/w0ps5rolaaeh/entries/${contentId}`;
        console.warn(
          `Attempted to display content with id ${contentId} containing an error. It is likely that content was erroneously deleted or unpublished. Find and removed references from ${contentLink} . `,
        );
        return <span style={{ display: "none" }}>content error</span>;
      case CustomContentModelType.PageContent:
        return (
          <PageContent
            key={uuidv5(JSON.stringify(content.fields), UUIDNamespace)}
            fields={content.fields}
            belowFold={options?.belowFold}
          />
        );
      case CustomContentModelType.ABTest:
        return (
          <ABTest
            key={uuidv5(JSON.stringify(content.fields), UUIDNamespace)}
            fields={content.fields}
            onHeroVisibilityDetected={options?.onHeroVisibilityDetected}
          />
        );
      case CustomContentModelType.IconBannerSection:
        return (
          <IconBanner
            key={uuidv5(JSON.stringify(content.fields), UUIDNamespace)}
            fields={content.fields}
          />
        );
      case CustomContentModelType.HeaderSection:
        return (
          <Header
            key={uuidv5(JSON.stringify(content.fields), UUIDNamespace)}
            fields={content.fields}
          />
        );
      case CustomContentModelType.TitleSection:
        return (
          <TitleSection
            key={uuidv5(JSON.stringify(content.fields), UUIDNamespace)}
            fields={content.fields}
          />
        );
      case CustomContentModelType.SectionPartnerHero:
        return (
          <PartnerHero
            key={uuidv5(JSON.stringify(content.fields), UUIDNamespace)}
            fields={content.fields}
          />
        );
      case CustomContentModelType.BannerSection:
        return (
          <BannerSection
            key={uuidv5(JSON.stringify(content.fields), UUIDNamespace)}
            fields={content.fields}
          />
        );
      case CustomContentModelType.DetailsSection:
        return (
          <DetailsSection
            key={uuidv5(JSON.stringify(content.fields), UUIDNamespace)}
            fields={content.fields}
          />
        );
      case CustomContentModelType.CalculatorSection:
        return (
          <CarFeature
            key={uuidv5(JSON.stringify(content.fields), UUIDNamespace)}
            fields={content.fields}
            belowFold={options?.belowFold}
          />
        );
      case CustomContentModelType.JobOpeningsSection:
        return (
          <JobOpenings
            key={uuidv5(JSON.stringify(content.fields), UUIDNamespace)}
            fields={content.fields}
          />
        );
      case CustomContentModelType.RoleDescriptionSection:
        return (
          <RoleDescription
            key={uuidv5(JSON.stringify(content.fields), UUIDNamespace)}
            fields={content.fields}
          />
        );
      case CustomContentModelType.StackablesSection:
        return (
          <StackablesSection
            key={uuidv5(JSON.stringify(content.fields), UUIDNamespace)}
            fields={content.fields}
          />
        );
      case CustomContentModelType.FeatureComparisonSection:
        return <FeatureComparison key="featurecomparison" />;
      case CustomContentModelType.PartnersAndQuotesSection:
        return <Testimonials key="testimonials" />;
      case CustomContentModelType.CommitmentSection:
        return <Commitment key="commitment" fields={content.fields} />;
      case CustomContentModelType.TestimonialsSection:
        return (
          <CustomerTestimonials
            key={uuidv5(JSON.stringify(content.fields), UUIDNamespace)}
            fields={content.fields}
            belowFold={options?.belowFold}
          />
        );
      case CustomContentModelType.FAQSection:
        return (
          <FAQSection
            key={uuidv5(JSON.stringify(content.fields), UUIDNamespace)}
            fields={content.fields}
          />
        );
      case CustomContentModelType.InTheNewsSection:
        return (
          <NewsSection
            key={uuidv5(JSON.stringify(content.fields), UUIDNamespace)}
            fields={content.fields}
          />
        );
      case CustomContentModelType.ContactUsSection:
        return <ContactUs key="contactus" />;
      case CustomContentModelType.FooterSection:
        return (
          <Footer
            key={uuidv5(JSON.stringify(content.fields), UUIDNamespace)}
            fields={content.fields}
          />
        );
      case CustomContentModelType.DescriptionPanelComp:
        return (
          <DescriptionPanel
            key={uuidv5(JSON.stringify(content.fields), UUIDNamespace)}
            fields={content.fields}
          />
        );

      case CustomContentModelType.Divider:
        return (
          <DividerSection
            key={uuidv5(JSON.stringify(content.fields), UUIDNamespace)}
            fields={content.fields}
          />
        );
      case CustomContentModelType.TeslaHero:
        return (
          <TeslaHero
            key={uuidv5(JSON.stringify(content.fields), UUIDNamespace)}
            fields={content.fields}
          />
        );
      case CustomContentModelType.StackableStepsSection:
        return (
          <StackableStepsSection
            key={uuidv5(JSON.stringify(content.fields), UUIDNamespace)}
            fields={content.fields}
          />
        );
      case CustomContentModelType.LargeImageSection:
        return (
          <LargeImage
            key={uuidv5(JSON.stringify(content.fields), UUIDNamespace)}
            fields={content.fields}
          />
        );
      case CustomContentModelType.CommonQuestionsSection:
        return (
          <CommonQuestions
            key={uuidv5(JSON.stringify(content.fields), UUIDNamespace)}
            fields={content.fields}
            belowFold={options?.belowFold}
          />
        );
      case CustomContentModelType.BlenderSection:
        return (
          <Blender
            key={uuidv5(JSON.stringify(content.fields), UUIDNamespace)}
            fields={content.fields}
          />
        );
      case CustomContentModelType.BlogSection:
        const createdAt = new Date(content.sys.createdAt);
        const fields = { ...content.fields, createdAt };
        return (
          <BlogSection
            key={uuidv5(JSON.stringify(content.fields), UUIDNamespace)}
            fields={fields}
          />
        );
      case CustomContentModelType.BlogCardSection:
        return (
          <BlogCardSection
            key={uuidv5(JSON.stringify(content.fields), UUIDNamespace)}
            fields={content.fields}
          />
        );
      case CustomContentModelType.ResponsiveImage:
        return (
          <ResponsiveImage
            key={uuidv5(JSON.stringify(content.fields), UUIDNamespace)}
            fields={content.fields}
            componentProps={componentProps}
          />
        );
      case CustomContentModelType.TeamCardsSection:
        return (
          <TeamCarousel
            key={uuidv5(JSON.stringify(content.fields), UUIDNamespace)}
            fields={content.fields}
          />
        );
      case CustomContentModelType.HomeHeroSection:
        return (
          <HomeHero
            key={uuidv5(JSON.stringify(content.fields), UUIDNamespace)}
            fields={content.fields}
            onVisible={options?.onHeroVisibilityDetected}
          />
        );
      case CustomContentModelType.LinkedTileCard:
        return (
          <LinkedTileCard
            key={uuidv5(JSON.stringify(content.fields), UUIDNamespace)}
            fields={content.fields}
          />
        );
      case CustomContentModelType.HowItWorks:
        return (
          <HowItWorks
            key={uuidv5(JSON.stringify(content.fields), UUIDNamespace)}
            fields={content.fields}
          />
        );
      case CustomContentModelType.GetInTouchCareersCard:
        return <GetInTouchCareersCard key="getintouchcareerscard" />;
      case CustomContentModelType.SplitLinkedCard:
        return (
          <SplitLinkedCard
            key={uuidv5(JSON.stringify(content.fields), UUIDNamespace)}
            fields={content.fields}
          />
        );
      case CustomContentModelType.Navbar:
        return (
          <Navbar
            key={uuidv5(JSON.stringify(content.fields), UUIDNamespace)}
            fields={content.fields}
          />
        );
      case CustomContentModelType.AddonsAndBenefits:
        return (
          <AddonsAndBenefits
            key={uuidv5(JSON.stringify(content.fields), UUIDNamespace)}
            fields={content.fields}
          />
        );
      case CustomContentModelType.AddOnPage:
        return (
          <AddOnPage
            key={uuidv5(JSON.stringify(content.fields), UUIDNamespace)}
            fields={content.fields}
          />
        );
      case CustomContentModelType.MakePage:
        return (
          <MakePage
            key={uuidv5(JSON.stringify(content.fields), UUIDNamespace)}
            fields={content.fields}
          />
        );
      case CustomContentModelType.RichText:
        return (
          <RichTextComponent
            key={uuidv5(JSON.stringify(content.fields), UUIDNamespace)}
            fields={content.fields}
          />
        );
      case CustomContentModelType.RichTextComponent:
        return (
          <RichTextComponent
            key={uuidv5(JSON.stringify(content.fields), UUIDNamespace)}
            fields={content.fields}
          />
        );
      case CustomContentModelType.EmailSubscriptionBanner:
        return (
          <EmailSubscriptionBanner
            key={uuidv5(JSON.stringify(content.fields), UUIDNamespace)}
            fields={content.fields}
          />
        );
      case CustomContentModelType.ValuePropSection:
        return (
          <ValuePropSection
            key={uuidv5(JSON.stringify(content.fields), UUIDNamespace)}
            fields={content.fields}
          />
        );
      case CustomContentModelType.AccordionSection:
        return (
          <AccordionSection
            key={uuidv5(JSON.stringify(content.fields), UUIDNamespace)}
            fields={content.fields}
          />
        );
      case CustomContentModelType.HeroWithIcon:
        return (
          <HeroWithIcon
            key={uuidv5(JSON.stringify(content.fields), UUIDNamespace)}
            fields={content.fields}
          />
        );
      case CustomContentModelType.SmallSection:
        return (
          <SmallSection
            key={uuidv5(JSON.stringify(content.fields), UUIDNamespace)}
            fields={content.fields}
          />
        );
      case CustomContentModelType.LargeSection:
        return (
          <LargeSection
            key={uuidv5(JSON.stringify(content.fields), UUIDNamespace)}
            fields={content.fields}
          />
        );
      case CustomContentModelType.Metadata:
        return (
          <Metadata
            key={uuidv5(JSON.stringify(content.fields), UUIDNamespace)}
            fields={content.fields}
          />
        );
      default:
        return null;
    }
  })();
  if (!contentSection) {
    return null;
  }
  // MPR, 2024/4/24: Let it be known that I do not like whatever this is. I'm planning to remove it soon.
  const customMargins: React.CSSProperties = {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions, @typescript-eslint/ban-ts-comment
    // @ts-ignore
    marginTop: `${content?.fields?.marginTop}px`,
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions, @typescript-eslint/ban-ts-comment
    // @ts-ignore
    marginBottom: `${content.fields?.marginBottom}px`,
  };
  return <div style={customMargins}>{contentSection}</div>;
};

export const renderContentModel: typeof renderContentModelWithoutBounds = (
  ...args
) => {
  const section = renderContentModelWithoutBounds(...args);
  if (!section) {
    return null;
  }
  const { key } = section;
  const next = cloneElement(section, { key: undefined });
  return <ErrorBoundary key={key}>{next}</ErrorBoundary>;
};

export const parseGenericPage = (
  pageSections: ContentfulCustomNodeImpl[],
  setReady: () => void,
  options?: {
    onHeroVisibilityDetected?: () => void;
    belowFold?: boolean;
  },
) => {
  const renderedSections = pageSections.map((c) => {
    const belowFold = (() => {
      return !!c.belowFold;
    })();
    return renderContentModel(c, undefined, { ...options, belowFold });
  });
  setReady();
  return renderedSections;
};
