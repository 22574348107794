import classNames from "classnames";
import React from "react";

import s from "./SectionWrapper.module.scss";

const SectionWrapper = ({
  children,
  backgroundColor,
  fullWidth,
  className,
}: {
  children: JSX.Element;
  backgroundColor?: string;
  fullWidth?: boolean;
  className?: string;
}) => {
  return (
    <div
      className={classNames(s.container, className)}
      style={{ backgroundColor }}
    >
      <div
        className={classNames(s.section, {
          [s.fullWidth]: fullWidth,
        })}
      >
        {children}
      </div>
    </div>
  );
};

export default SectionWrapper;
